<template>
  <div class :style="{background:(danisok==true?'#013bba':aid==66?'#45b97c':'#6c1000')}" v-if="showCard">
    <div class="topp" :style="{background:(danisok==true?'#013bba':aid==66?'#45b97c':'#6c1000')}">
      <div class="hed_a">
        <div class="liansuo" v-if="danisok==true">
          <div class="tite" :class="fsiz == true?'ziti':''">{{list.storeAbbreviation}}</div>
          <div class="tite2">{{list.activityName}}</div>
          <div class="tite3">
            <div class="tite3_a" style="display: inline-block;">{{list.storeDescription}}</div>
          </div>
          <div class="tite4">—{{list.activityBrief}}—</div>
          <img src="../../assets/img/fen_img.png" style="display: none" alt />
        </div>
        <div class="dandian" v-else></div>
        <div class="XMlist">
          <div class="vxbeijin" v-if="danisok==true">
            <div class="huikui">
              感恩回馈仅需
              <span class="jinyuan">{{list.activityPrice}}</span> 元
            </div>
            <div class="yunajia">
              <span class="yunajia_A">
                原价
                <span class="yunajia_b">¥{{list.par}}</span> 元
              </span>
            </div>
          </div>
          <div class="newbeijin" v-else>
            <img class="newimg" v-if="aid=='66'" src="../../assets/img/newbeijin3.png" alt />
            <img class="newimg" v-else src="../../assets/img/newbeijin2.png" alt />
          </div>
          <div class="xiangshou">
            即可享受以下
            <span class="xiangshou_a">{{index==0?index:index+1}}</span>
            项全部服务
          </div>
          <div class="list_xianmu">
            <div v-for="(items,idx) in shangjia.activityItems" :key="idx">
              <div class="list_xianmu_a" v-for="(item,index) in items.items" :key="index">
                <img v-if="item.itemImg" class="xm_img" :src="item.itemImg" alt />
                <img v-else class="xm_img" src="../../assets/img/zanwu.png" alt />
                <div class=" xm_neirong">
                  <div class="xiche_a">{{item.itemName}}</div>
                  <div class="xiche_b">{{item.itemDes}}</div>
                  <div class="xiche_c">
                    <span v-if="item.unit != '不限'">{{item.totalTimes}}</span>
                    <span>{{item.unit}}</span>

                  </div>
                </div>
                <div class=" jiazhi" v-if="item.perPrice != 0">
                  <div class="jiazhi_a">价值</div>
                  <div class="jiazhi_b">¥{{item.perPrice * item.totalTimes}}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- v-if="danisok==true" -->
          <div class="miansm" v-if="danisok==true">
            <div class="xianmu_sm">
              <span style="color: #DF3D48;">*</span>
              {{list.jieshu}}起即可到店施工，激活后各项目1年内有效
            </div>
            <div class="xianmu_sm2">
              <span style="color: #DF3D48;">*</span>
              使用流程：1. 线上抢购 2. {{list.activationStartDate?list.activationStartDate.substr(5,5):''}}日-{{list.activationEndDate?list.activationEndDate.substr(5,5):''}}日到店兑换服务卡
            </div>
            <div class="xianmu_sm2" style="margin-left: 75px;">3. {{list.jieshu}}起预约到店施工</div>
          </div>
          <div class="miansmnew" v-else>
            <div class="zhuyi" :style="{background:(aid==66?'#45b97c':'#d00808')}">注意事项</div>
            <div class="xianmu_sm" style="margin-top: 5px;padding-left: 2px;">
              1、线上抢购
            </div>
            <!-- <div class="xianmu_sm2">2、{{list.activationStartDate?list.activationStartDate.substr(5,5):''}}日-{{list.activationEndDate?list.activationEndDate.substr(5,5):''}}日到店兑换服务卡</div> -->
            <div class="xianmu_sm2">2、{{list.jieshu}}起预约到店施工</div>
            <div class="xianmu_sm2">3、本次活动仅限私家车及SUV参与 丨 特价产品不设退款</div>
          </div>
          <!-- <div class="miansmnew" v-else>
            <div class="zhuyi">注意事项</div>
            <div class="xianmu_sm" style="margin-top: 5px;padding-left: 2px;">
              1、此卡11月11号后可到店施工
            </div>
            <div class="xianmu_sm2">2、洗车券每月可用2张，且需要预约使用，不预约门店有权拒绝服务，过期补3元/张差价后可继续预约使用。</div>
            <div class="xianmu_sm2">3、赠送礼品请到门店前台领取</div>
            <div class="xianmu_sm2">4、本次活动仅限私家车及SUV参与 丨 特价产品不设退款</div>
          </div> -->
        </div>
        <div class="sp_beijin" v-if="list.feeName">
          <img v-if="list.feeUrl" :src="list.feeUrl" class="sp_img" alt />
          <img v-else class="sp_img" src="../../assets/img/zanwu.png" alt />
          <div class="left sp_lis">
            <div class="sp_lis_a">{{list.feeName}}</div>
            <div class="sp_lis_b">获取方式详询店内工作人员</div>
            <div class="sp_lis_c">每人限领1份 丨 数量有限 丨 先到先得</div>
          </div>
          <div class="song">
            <div class="song_a">价值</div>
            <div class="song_b">
              <span class="jz_b">¥</span>
              <span class="jz_c">{{list.feePrice}}</span>
              <span class="jz_d">元</span>
            </div>
          </div>
        </div>
        <div class="beijin">
          <div class="mian" v-if="danisok==true">
            <div class="mian_a">
              购买后凭本人行驶证于
              <span style="color: #DF3D48;">{{list.activationStartDate?list.activationStartDate:''}}日 一 {{list.activationEndDate?list.activationEndDate.substr(5,5):''}}日</span>
              到店激活
            </div>
            <div class="mian_a">
              激活后
              <span style="color: #DF3D48;">领取1年有效期服务卡</span>
            </div>
            <div class="mian_a">未在指定时间内激活的卡券将自动失效。</div>
            <div class="mian_b">本次活动仅限私家车及SUV参与 丨 特价产品不设退款</div>
          </div>
          <div style="height: 12px;" v-else></div>
          <van-row class="user_links">
            <van-col span="5" class="jiachu" @click="tocheshi">服务门店:</van-col>
            <van-col class="yichu" span="16" @click="mendianshow = !mendianshow">{{shangjia.name}}</van-col>
            <van-col span="4" class="xuanze" @click="mendianshow = !mendianshow">
              <img class="ditu_biao" src="../../assets/img/qiehuan.png" alt />
            </van-col>
          </van-row>
          <van-row class="user_links">
            <van-col span="5" class="jiachu">门店地址:</van-col>
            <van-col span="16" class="dizhi" @click="daohang">
              <div class="dizhineirong">{{shangjia.address}}（点击查看导航）</div>
            </van-col>
            <div class="xuanze" @click="daohang">
              <img class="ditu_biao" src="../../assets/img/ditu.png" alt />
            </div>
          </van-row>
          <van-row class="user_links">
            <van-col span="5" class="jiachu">商家电话:</van-col>
            <a :href="'tel:'+shangjia.phone">
              <van-col span="16" class="yichu">{{shangjia.phone}}</van-col>
              <van-col span="4" class="xuanze">
                <img class="ditu_biao" src="../../assets/img/dianhua.png" alt />
              </van-col>
            </a>
          </van-row>
          <van-row class="user_links">
            <van-col span="5" class="jiachu">换卡时间:</van-col>
            <van-col span="16" class="yichu">{{list.activationStartDate}}~{{list.activationEndDate}}</van-col>
            <van-col span="4" class="xuanze"></van-col>
          </van-row>
          <div class="car-number">
            <van-row class="user_links2">
              <van-col span="5" class="chepeiib">车牌号码:</van-col>
              <van-col span="17" @click.stop="showKeyBoard = true" style="float: right">
                <div class="item vux-1px-l" v-for="(v, i) in carNumberArr" :key="i">
                  <div class="flex">{{carNumber[i] || ''}}</div>
                </div>
              </van-col>
            </van-row>
          </div>
          <van-row class="user_links" style="border-top: 1px solid #dcdcdc;border-bottom:none;">
            <van-col span="5" class="chepeii">手机号码:</van-col>
            <van-col span="18">
              <van-field v-model="addForm.phone" input-align="right" type="tel" placeholder="请输入手机号码" />
            </van-col>
          </van-row>
        </div>

        <div class="renzhen" v-if="!pinzhenisok">
          <div class="renzhen_a" @click="zhankai = !zhankai">
            <div class="renzhen_h1">你已购买此次活动，期待您光临门店！</div>
            <div class="renzhen_h2">
              <span style="vertical-align: middle;margin-right: 3px;">查看凭证信息</span>
              <van-icon style="vertical-align: middle;" :name="!zhankai?'arrow-down':'arrow-up'" />
            </div>
          </div>
          <div class="renzhen_b" v-if="zhankai">
            <div class="hh2">
              出示以下凭证码
              <van-button plain class="right" type="default" size="small" @click="copy">复制凭证码</van-button>
            </div>

            <div class="hh3">
              <div class="left pzma">核销凭证码</div>
              <div class="left" style="width: 90%;">
                <div class="cc_ma">凭证码：{{pinzhenma.orderNumber}}</div>
                <div class="qy_code_wrapper">
                  <vue-qr :text="'http://customer.car-posthouse.cn/matching/verification.html?orderNumber='+pinzhenma.orderNumber" :margin="10" colorDark="#000" colorLight="#fff" :dotScale="1" logoSrc :logoScale="0.2" :size="160"></vue-qr>
                </div>
                <div class="tishi">请出示此二维码到门店前台核销</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="tuijiantu" v-if="!danisok">
          <img class="tuijianimg" v-if="aid == '66'" src="../../assets/img/tuijian.png" alt />
          <img class="tuijianimg" v-else src="../../assets/img/tuijian2.png" alt />
        </div> -->

        <van-swipe class="my-swipe" v-if="list.carouselOne || list.carouselTwo" :autoplay="4000" indicator-color="white">
          <van-swipe-item v-if="list.carouselOne">
            <img :src="list.carouselOne" alt />
            <span class="fen_shu">{{list.redPackCount}}</span>
          </van-swipe-item>
          <van-swipe-item v-if="list.carouselTwo">
            <img :src="list.carouselTwo" alt />
          </van-swipe-item>
        </van-swipe>
        <div class="foot">
          <div class="jifen">
            <img class="huo" src="../../assets/img/huo.png" alt />
            <span style="vertical-align: middle;">
              截止目前已有
              <span style="font-size: 18px;color: red;">{{total}}</span> 人抢购
            </span>
            <img class="huo" src="../../assets/img/huo.png" alt />
          </div>
          <div class="jifen_a">
            <van-row class="jifen_b">
              <van-col span="3">序号</van-col>
              <van-col span="6">车牌号</van-col>
              <van-col span="7">卡券状态</van-col>
              <van-col span="8" style="border-right: none">购买时间</van-col>
            </van-row>
            <van-row class="jifen_c" v-for="(item, idx) in joinList" :key="idx">
              <van-col span="3">{{total-idx}}</van-col>
              <van-col span="6">{{item.carNo}}</van-col>
              <van-col span="7">
                <span style="color: #DF3D48;" v-if="item.status==0">未领取</span>
                <span style="color: #249021;" v-else>已领取</span>
                <!-- {{item.status==0?'未领取':'已领取'}} -->
              </van-col>
              <van-col span="8">{{item.payDate?item.payDate.substr(0,11):''}}</van-col>
            </van-row>
            <van-row class="jifen_d" @click="chakangenduo" v-if="!finished">
              <van-col span="24">
                <span style="vertical-align: middle">查看更多</span>
                <van-icon style="vertical-align: middle" name="arrow-down" />
              </van-col>
            </van-row>
          </div>
        </div>
        <div class="gzhimg" :style="{background:(danisok==true?'#013bba':aid==66?'#45b97c':'#6c1000')}">
          <div class="gzname">关注【驿车驹】公众号享红包</div>
          <div style="position: relative;">
            <img class="erweima" src="../../assets/img/er.jpg" alt />
            <span class="dis">@2019 驿车驹</span>
          </div>

          <div class="gzname_a">
            <img class="beian" src="../../assets/img/beian.png" alt />
            <span style="vertical-align: middle;display: inline-block">粤ICP备19149653号</span>
          </div>
        </div>

        <van-row class="msgHtmlBox ql-snow ql-editor" v-html="list.content"></van-row>
        <div style="height: 50px;"></div>
        <div class="bott">
          <!-- <a :href="'tel:'+shangjia.phone"> -->
          <div class="left boda">
            <div class="left" :class="list.limitCount > 999 ?'fener':'activefener'">{{list.limitCount}}</div>
            <div class="right">
              <van-count-down millisecond :time="day" format="HH:mm:ss:SS">
                <template #default="timeData">
                  <span class="jiandaot" v-if="day > 86400000">
                    <span style="margin-right: 1px;" class="jiandaot_t">{{ timeData.days }}</span>
                    <span>天</span>
                  </span>
                  <!-- <span class="colon" v-if="day > 86400000">:</span> -->
                  <span class="jiandao">{{ timeData.hours }}</span>
                  <span class="colon">:</span>
                  <span class="jiandao">{{ timeData.minutes }}</span>
                  <span class="colon">:</span>
                  <span class="jiandao">{{ timeData.seconds }}</span>
                  <span class="colon">.</span>
                  <span class="jiandao">{{ timeData.milliseconds}}</span>
                </template>
              </van-count-down>
            </div>
            <!-- <img class="ddhh" src="../../assets/img/ddhh.png" alt />
            <span style="vertical-align: middle;">电话咨询</span>-->
          </div>
          <!-- </a> -->
          <div class="lb_tijiao" v-if="pinzhenisok" @click="tijiao" v-preventReClick>
            <img class="ddhh" src="../../assets/img/goumai.png" alt />

            <span style="vertical-align: middle;">{{day > 0?'立即报名':'活动已结束'}}</span>
          </div>
          <div class="lb_tijiao" v-else>
            <img class="ddhh" src="../../assets/img/goumai.png" alt />
            <span style="vertical-align: middle;">您已报名</span>
          </div>
        </div>
        <img class="fangwen" v-if="!iszhezhao" @click="tofanwen" src="../../assets/img/fw.png" alt />
        <img class="shuju" v-if="!iszhezhao" @click="toyeji" src="../../assets/img/shuju.jpg" alt />
        <img class="shujuerw" v-if="!iszhezhao" @click="toerweima" src="../../assets/img/toewm.png" alt />
        <img class="shujuhb" v-if="!iszhezhao" @click="tohb" src="../../assets/img/tohongbao.png" alt />
        <img class="shujuhb fxtops" v-if="!iszhezhao" @click="zsisok = true" src="../../assets/img/fenxiang.png" alt />
      </div>
      <div class="yanshe" v-if="danisok"></div>
      <div :class="aid=='66'?'yanshehong2':'yanshehong'" v-else></div>
    </div>
    <van-popup class="gomform" v-model="goumaishow">
      <div class="gmtishi" style="color:#333">
        <span style="color:red">恭喜你！</span>
        <span>成功参与本次活动，下面请您花点时间注册一下，关注公众号后，您可以到"我的——优惠券"中查看，</span>
        <span style="color:red">11月11日</span>
        <span>后可</span>
        <span style="color:red">预约</span>
        <span>施工。</span>
      </div>
      <div class="zhucexinxi">
        <h1 class="h1tite">注册信息</h1>
        <van-cell-group>
          <van-field class="zcheng" readonly v-model="addForm.carNo" label="车牌号码:" placeholder="请输入用户名" />
        </van-cell-group>
        <van-cell-group>
          <van-field class="zcheng" readonly v-model="addForm.phone" label="手机号码:" placeholder="请输入用户名" />
        </van-cell-group>
        <van-cell-group>
          <van-field class="zcheng" readonly v-model="chexin" @click="tochexin" label="车型:" placeholder="请选择" />
        </van-cell-group>
        <div class="isgoms">
          <van-checkbox class="gouxuan" checked-color="#ee0a24" shape="square" v-model="checked">
            <span style="color:#ee0a24">找不到我的车型</span>
          </van-checkbox>
        </div>
        <van-cell-group>
          <van-field class="zcheng" v-model="name" label="您的称呼:" placeholder="选填" />
        </van-cell-group>
        <van-button class="anssniu" type="primary" @click="ctijiao" v-preventReClick>填好了</van-button>
      </div>
    </van-popup>
    <!-- 遮罩提示层 -->
    <!-- <div class="zhezhao" v-if="iszhezhao">
      <div style="overflow: hidden;" v-if="isfenxiang">
        <div class="fen_img">
          <div class="fen_tit">分享给好友购买享红包</div>
          <div class="fen_jin">
            转介红包
            <span>{{list.firstRed}}元</span>
          </div>
          <div class="fen_shuoming" style="margin-top: 6px;">分享给好友购买，红包马上返回</div>
          <div class="fen_jin" style="padding-top: 9%;">
            转介红包
            <span>{{list.secondRed}}元</span>
          </div>
          <div class="fen_shuoming">您的好友分享给他的下一个好友购买，</div>
          <div class="fen_shuoming">您仍然有红包返</div>
          <div class="fen_tishi">快来尽情参与我们的红包活动吧！</div>
          <div class="fen_xia" @click="fenclick">下一步</div>
        </div>
        <div class="fen_ico">
          <img class="ico_a" src="../../assets/img/tohongbao.png" alt />
          <span class="ico_name">点此查看我的红包</span>
        </div>
        <div class="fen_ico">
          <img class="ico_a" src="../../assets/img/toewm.png" alt />
        </div>
      </div>
      <div class="chaozuo" v-if="ischaozuo">
        <div class="shiyi_name">分享操作示意图</div>
        <img class="czimg" :src="chaozuoimgs[xyb].img" alt />
        <div class="czxiayibu" @click="chaozuoxyb">{{xyb==3?'关闭':"下一步"}}</div>
      </div>
    </div>-->
    <van-popup class="tang" round v-model="mendianshow" position="bottom">
      <van-picker show-toolbar :columns="list.garageInfoDOS" value-key="name" @cancel="mendianshow = false" @confirm="onConfirm" />
    </van-popup>
    <carPlate ref="ple" v-show="pleshow" style="z-index: 999;" :plate_number="addForm.carNo" @getPlate="getPlate" @getPlate2="getPlate2"></carPlate>
    <key-board v-show="showKeyBoard" @getPlateb="getPlateb" v-model="carNumber" :car_type="type"></key-board>
    <van-dialog v-model="gzisok" title="提 示" show-cancel-button :show-confirm-button="false" confirm-button-text="去关注" cancel-button-text="取消" @confirm="tijiaoshenhe" @cancel="quxiaotu">
      <div class="tishic" style="color:#666">请先关注【驿车驹】公众号，转发朋友购买即可享受红包奖励！</div>
      <div class="gzhimgc">
        <img class="erweima" src="../../assets/img/er.jpg" alt />
        <div class="cctishi">长按二维码图片可识别关注公众号！</div>
      </div>
    </van-dialog>
    <div class="zszhezhao" v-if="zsisok" @click="zsisok = false">
      <img src="../../assets/img/zhishi.png" alt />
    </div>
    <div class="chege" v-if="cheisok">
      <div class="biao_ti">
        <van-icon @click="back" class="fanhui" name="arrow-left" />
        <span style="vertical-align: middle;">选择车型</span>
      </div>
      <van-search show-action class="shousuo" v-model="brandName" placeholder="请输入搜索关键词">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <div style="padding:108px 0px 0px 0px;">
        <div class="remen">热门品牌</div>
        <div class="remen_a">
          <div class="remen_b" v-for="(item,idx) in remen" :key="'rss'+idx" @click="chelist(item)">
            <img class="r_img" :src="item.ppinpaiLogoUrl" alt />
            <span>{{item.ppinpai}}</span>
          </div>
        </div>
        <van-index-bar>
          <van-index-anchor v-for="(item,idx) in listchen" :key="'idd'+idx" :index="idx">
            <div class="indexWord">{{idx}}</div>
            <div v-for="(items,index) in item" :key="index" class="p_list" @click="chelist(items)">
              <img class="pinpai_img" :src="items.ppinpaiLogoUrl" alt />
              <span class="pinpai_txt">{{items.ppinpai}}</span>
            </div>
          </van-index-anchor>
        </van-index-bar>
      </div>
      <van-popup style="z-index: 9999;" v-model="chexinshow" position="right" :style="{ height: '100%',width: '80%' }">
        <van-search show-action class="xinshou" v-model="seriesName" placeholder="请输入搜索关键词">
          <template #action>
            <div @click="onSearch2">搜索</div>
          </template>
        </van-search>
        <div class="m_lis">
          <img class="pinpai_img" :src="imgs" alt />
          <span class="imname">{{imgname}}</span>
        </div>
        <div class="huadong">
          <div v-for="(item,ind) in carlist" :key="ind" class="chexi" @click="baocun(item)">{{item.seriesName}}</div>
          <div style="height:100px"></div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";
import preventReClick from "../../assets/preventReClick";
import carPlate from "../../components/carplate.vue";
import KeyBoard from "@/components/KeyBoard.vue";
// 引入
// 使用

// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
import vueQr from "vue-qr";
// import 'lib-flexible/flexible'
export default {
  name: "index",
  // mixins: [preventBack], //注入
  components: {
    carPlate,
    vueQr,
    KeyBoard,
  },
  data() {
    return {
      animate: false,
      iszhezhao: false,
      goumaishow: false,
      showCard: false,
      name: "",
      isfenxiang: false,
      checked: false,
      ischaozuo: false,
      zsisok: false,
      chaozuoimgs: [
        { img: require("../../assets/img/chaozuo1.jpg") },
        { img: require("../../assets/img/chaozuo2.jpg") },
        { img: require("../../assets/img/chaozuo3.png") },
        { img: require("../../assets/img/chaozuo4.jpg") },
      ],
      carNumberArr: ["", "", "", "", "", "", "", ""],
      carNumber: [],
      showKeyBoard: false,
      xyb: 0,
      gzisok: false,
      type: "0",
      joinList: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      active: 0,
      mendianshow: false,
      addForm: {
        carNo: "",
        phone: "",
      },
      pleshow: false,
      danisok: true,
      chexin: "",
      uid: "",
      ssid: "",
      time: "0",
      listimg: [],
      listfenxiamg: [],
      list: {},
      shangjia: {},
      aid: "",
      zhankai: false,
      pinzhenma: "",
      index: "",
      downloadData: {},
      pinzhenisok: true,
      activityItems: {},
      total: 0,
      page: 0,
      size: 50,
      finished: false,
      gid: "",
      times: null,
      dingshi: 0,
      ceshi: 0,
      appid: "wx406b62706ded5aa8",
      isok: "true",
      isWxMini: "",
      day: 10,
      guanzhu: false,
      fxisok: false,
      isokguanzhu: null,
      vxMange: 0, //0没有权限 1有权限
      code: "", // 前端获取 code 传给后端调用相应接口
      //***********------------------------- */
      carBrandLogo: "",
      cheisok: false,
      chexinshow: false,
      addForm: {},
      brandName: "",
      remen: [
        {
          id: 256,
          pgengxinshijian: "201903",
          ppinpai: "大众",
          ppinpaiId: "1",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/1.jpg",
          pshouzimu: "D",
        },
        {
          id: 297,
          pgengxinshijian: "201903",
          ppinpai: "本田",
          ppinpaiId: "14",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/14.jpg",
          pshouzimu: "B",
        },
        {
          id: 236,
          pgengxinshijian: "201903",
          ppinpai: "丰田",
          ppinpaiId: "3",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/3.jpg",
          pshouzimu: "F",
        },
        {
          id: 295,
          pgengxinshijian: "201903",
          ppinpai: "别克",
          ppinpaiId: "38",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/38.jpg",
          pshouzimu: "B",
        },
        {
          id: 3,
          pgengxinshijian: "201903",
          ppinpai: "福特",
          ppinpaiId: "8",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/8.jpg",
          pshouzimu: "F",
        },
        {
          id: 87,
          pgengxinshijian: "201903",
          ppinpai: "日产",
          ppinpaiId: "63",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/63.jpg",
          pshouzimu: "R",
        },
        {
          id: 30,
          pgengxinshijian: "201903",
          ppinpai: "现代",
          ppinpaiId: "12",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/12.jpg",
          pshouzimu: "X",
        },
        {
          id: 1,
          pgengxinshijian: "201903",
          ppinpai: "奥迪",
          ppinpaiId: "33",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/33.jpg",
          pshouzimu: "A",
        },
        {
          id: 29,
          pgengxinshijian: "201903",
          ppinpai: "雪佛兰",
          ppinpaiId: "71",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/71.jpg",
          pshouzimu: "X",
        },
        {
          id: 296,
          pgengxinshijian: "201903",
          ppinpai: "宝马",
          ppinpaiId: "15",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/15.jpg",
          pshouzimu: "B",
        },
      ],

      value: "",
      fsiz: false,
      brandId: "",
      listchen: [],
      carlist: [],
      imgs: "",
      seriesName: "",
      imgname: "",
    };
  },
  watch: {
    carNumber() {
      console.log(this.type);
      if (
        (this.type != 2 && this.carNumber.length === 8) ||
        this.carNumber.length === 8
      ) {
        this.showKeyBoard = false;
      }
    },
    dingshi: function (newVal, oldVal) {
      console.log(newVal);
      console.log(oldVal);
    },
    type() {
      if (this.type == 2) {
        if (this.carNumberArr.length == 7) {
          this.carNumberArr.push("");
          this.showKeyBoard = true;
        }
      } else if (this.carNumberArr.length == 8) {
        this.carNumberArr.pop();
        this.showKeyBoard = false;
      }
    },
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  activated() {
    console.log(this.$router);
    // 禁止 安卓返回按钮返回事件处理
    document.addEventListener("backbutton", this.refresh, false);
  },
  deactivated() {
    document.removeEventListener("backbutton", this.refresh, false);
  },
  created() {
    console.log(location.href.split("#"));
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
    var _this = this;
    this.uid = utils.getUrlKey("uid");
    this.aid = utils.getUrlKey("aid");
    this.ssid = utils.getUrlKey("ssid");
    this.gid = utils.getUrlKey("gid");
    // this.fxisok = utils.getUrlKey("fxisok");

    if (localStorage.getItem("hdtoken")) {
      this.pinzhen();
      this.listname();
      this.guanzhuisok();
      this.fangwen();
      // var isok = localStorage.getItem("isok");
      // console.log(this.userInfo);

      // if (isok == "true") {
      //   setTimeout(res => {
      //     this.$router.go(0);
      //   }, 100);
      //   localStorage.removeItem("isok");
      // }
    } else {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&aid=" +
        this.$route.query.aid +
        "&uid=" +
        this.$route.query.uid +
        "&ssid=" +
        this.$route.query.ssid;
      // +
      // "&fxisok=" +
      // this.$route.query.fxisok;
      localStorage.setItem("beforeUrl", url);
      // localStorage.removeItem("hdtoken");
      var appid = "wx406b62706ded5aa8";
      // //  授权请求, 并跳转
      const redirect_uri = encodeURIComponent(
        "https://marketing.car-posthouse.cn/auth"
      );
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    }
    // this.pinzhen();
    // this.listname();
    // this.guanzhuisok();
    // this.getConfig();
    // this.fangwen();
  },
  methods: {
    //跳转页面
    toyeji() {
      if (sessionStorage.getItem("vxMange") == 0) {
        this.$router.push({
          path: "information",
          query: {
            aid: this.aid,
          },
        });
      } else {
        this.$router.push({
          path: "information_qx",
          query: {
            aid: this.aid,
          },
        });
      }
    },
    tofanwen() {
      this.$router.push({
        path: "visit",
        query: {
          aid: this.aid,
        },
      });
    },
    //跳转二维码页面
    toerweima() {
      var that = this;
      var link =
        "https://marketing.car-posthouse.cn/user?aid=" +
        that.aid +
        "&ssid=" +
        that.uid +
        "&gid=" +
        that.list.gid +
        "&uid=" +
        that.userInfo.userId;
      this.$router.push({
        path: "erweima",
        query: {
          link: link,
          title: this.list.activityName,
          aid: this.aid,
        },
      });
    },
    //跳转红包战绩页面
    tohb() {
      this.$router.push({
        path: "standings",
        query: {
          aid: this.aid,
        },
      });
    },
    //添加访问记录
    fangwen() {
      var data = {
        aid: this.aid,
        sid: this.uid,
        uid: this.userInfo.userId,
      };
      userModel.marketaddCounts(data).then((res) => {
        if (res.code == 200) {
        }
      });
    },
    //是否购买
    pinzhen() {
      userModel
        .isBuy({
          aid: this.aid,
          // aid: 18
        })
        .then((res) => {
          console.log(res.data);
          // window.clearInterval(this.times);
          if (res.code == 200) {
            sessionStorage.setItem("vxMange", res.data.vxMange);
            if (res.data.carNo == null) {
              console.log("没用买过");
              this.pinzhenisok = true;
            } else {
              console.log("买过了");
              this.pinzhenma = res.data;
              this.addForm.phone = res.data.phone;
              this.addForm.carNo = res.data.carNo;
              this.carNumber = res.data.carNo.split("");
              this.pinzhenisok = false;
              if (
                res.data.register == 0 &&
                res.data.carNo != null &&
                this.gid == 484
              ) {
                this.goumaishow = true;
              }
            }
            // this.listfenxiamg = res.data;
          }
        });
    },
    guanzhuisok() {
      userModel
        .subscribe({
          uid: this.userInfo.userId,
          // uid:9105,
          gid: 208,
          // aid: 18
        })
        .then((res) => {
          this.isokguanzhu = res.data;
          if (res.data == 1) {
            this.gzisok = false;
            window.clearInterval(this.times);
          }
        });
    },
    //
    tijiao() {
      var carNo = this.carNumber.join("");
      if (this.shangjia.gid == "" || this.shangjia.gid == null) {
        this.$toast("请先选择核销门店！");
        return;
      }
      if (this.day <= 0) {
        this.$toast("该活动已截止");
        return;
      }
      // //判断是否关注公众号
      // if (this.isokguanzhu == 0 && this.guanzhu == false) {
      //   this.gzisok = true;
      //   this.guanzhu = true;
      //   var _this = this;
      //   var num = 1;
      //   this.times = setInterval(() => {
      //     //应用setInterval来刷新getList()
      //     num++;
      //     this.guanzhuisok();
      //     if (num == 30) {
      //       window.clearInterval(this.times);
      //     }
      //     console.log(num);
      //   }, 2500); //一分钟
      //   return;
      // }
      var vm = this;
      if (carNo == "") {
        this.$toast("请先输入车牌号码！");
        return;
      }
      if (carNo.length < 7) {
        this.$toast("请输入正确的车牌号码！");
        return;
      }
      // if (this.carNumber == "") {
      //   this.$toast("请先输入车牌号码！");
      //   return;
      // }
      if (this.addForm.phone == "") {
        this.$toast("请先输入手机号码！");
        return;
      } else if (!/^1[23456789]\d{9}$/.test(this.addForm.phone)) {
        this.$toast("手机号码格式错误");
        return;
      }

      let data = {
        aid: this.aid,
        carModel: "",
        carNo: carNo,
        payAmount: this.list.activityPrice,
        offGid: this.shangjia.gid,
        gid: 208,
        phone: this.addForm.phone,
        sid: this.uid,
        ssId: this.ssid,
        uid: this.userInfo.userId,
      };
      userModel.addCar(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          //0元购
          if (this.list.activityPrice == 0) {
            var lis = {
              activityId: vm.aid,
              activityName: res.data.activityName,
              gid: res.data.gid,
              activityId: res.data.activityId,
              orderNumber: res.data.orderNumber,
              payAmount: res.data.payAmount,
              userId: res.data.userId,
              superiorId: res.data.superiorId,
            };
            userModel.payzero(lis).then((res) => {
              if (res.code == 200) {
                vm.joinList = [];
                vm.$toast("下单成功");
                vm.pinzhen();
                vm.page = 0;
                vm.listname();
                vm.guanzhuisok();
                vm.getConfig();
              }
            });
          } else {
            var isWxMini = window.__wxjs_environment === "miniprogram";
            console.log(isWxMini);
            var lis = {
              activityName: res.data.activityName,
              gid: res.data.gid,
              activityId: res.data.activityId,
              orderNumber: res.data.orderNumber,
              payAmount: res.data.payAmount,
              userId: res.data.userId,
              superiorId: res.data.superiorId,
            };
            if (isWxMini) {
              lis.token = localStorage.getItem("hdtoken");
              let payDataStr = JSON.stringify(lis);
              wx.miniProgram.navigateTo({
                url: "/pages/pay/pay?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
              });
            } else {
              userModel.pay(lis).then((res) => {
                if (res.code == 200) {
                  if (typeof WeixinJSBridge === "undefined") {
                    // 微信浏览器内置对象。参考微信官方文档
                    if (document.addEventListener) {
                      document.addEventListener(
                        "WeixinJSBridgeReady",
                        vm.onBridgeReady(res.data),
                        false
                      );
                    } else if (document.attachEvent) {
                      document.attachEvent(
                        "WeixinJSBridgeReady",
                        vm.onBridgeReady(res.data)
                      );
                      document.attachEvent(
                        "onWeixinJSBridgeReady",
                        vm.onBridgeReady(res.data)
                      );
                    }
                  } else {
                    console.log("准备调用微信支付");
                    vm.onBridgeReady(res.data);
                  }
                }
              });
            }
          }
        }
      });
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: "wx406b62706ded5aa8", // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign, // 微信签名
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast("下单成功");
            vm.joinList = [];
            vm.page = 0;
            vm.pinzhen();
            vm.listname();
            vm.guanzhuisok();
            vm.getConfig();
            if (vm.list.gid == 484) {
              vm.goumaishow = true;
            }

            // vm.$router.go(0);
          } else {
            vm.$toast("下单失败");
          }
        }
      );
    },

    daohang() {
      //  latitude: 22.912715, // 纬度，浮点数，范围为90 ~ -90
      // longitude: 123.456789, // 经度，浮点数，范围为180 ~ -180。
      // name: '黄江', // 位置名
      // address: '修理厂', // 地址详情说明

      var positionWd = Number(this.shangjia.positionWd);
      var positionJd = Number(this.shangjia.positionJd);
      //  console.log(positionJd)
      wx.openLocation({
        latitude: positionWd, // 纬度，浮点数，范围为90 ~ -90
        longitude: positionJd, // 经度，浮点数，范围为180 ~ -180。
        name: this.shangjia.name, // 位置名
        address: this.shangjia.address, // 地址详情说明
        scale: 13, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
      });
    },
    //获取访问次数
    visit_times() {
      var data = {
        uid: this.userInfo.userId,
        aid: this.aid,
      };

      userModel.appletlog(data).then((res) => {
        if (res.code == 200) {
        }
      });
    },
    listname() {
      var _this = this;
      userModel.yinxiaodelete({ id: this.aid }).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          _this.getConfig();
          if (res.data.storeAbbreviation) {
            if (res.data.storeAbbreviation.length > 5) {
              this.fsiz = true;
            }
          }

          if (res.data.gid == 484) {
            // this.danisok = false;
            if (_this.uid == "0" || _this.uid == 0) {
              _this.visit_times();
            }
          }
          this.list.par = 0;
          this.shangjia = res.data.garageInfoDOS[0];

          var isdex = 0;
          console.log(this.list);
          var jieshu = new Date(this.list.activationEndDate);
          jieshu = new Date(jieshu.setDate(jieshu.getDate() + 1));
          jieshu =
            jieshu.getFullYear() +
            "-" +
            (jieshu.getMonth() + 1 > 9
              ? jieshu.getMonth() + 1
              : "0" + (jieshu.getMonth() + 1)) +
            "-" +
            (jieshu.getDate() > 9 ? jieshu.getDate() : "0" + jieshu.getDate());
          this.list.jieshu = jieshu;

          var date = new Date().getTime();
          var time1 = res.data.activityEndDate.replace(/-/g, "/");
          var until = new Date(time1).getTime();

          var days = until - date;
          // 下面都是简单的数学计算
          _this.day = days + 86399000;
          console.log(this.day);
          this.shangjia.activityItems.forEach((item, index) => {
            if (item.items) {
              item.items.forEach((items, idx) => {
                _this.index = isdex++;
                _this.list.par += items.perPrice * items.totalTimes;
              });
              console.log(_this.index);
            }
          });
          sessionStorage.setItem("LBlist", JSON.stringify(this.list));
          sessionStorage.setItem("LBshangjia", JSON.stringify(this.shangjia));
          this.showCard = true;
        }
        if (this.list.status == 3) {
          _this.day = 0;
        }
        var isWxMini = window.__wxjs_environment === "miniprogram";
        console.log(isWxMini);
        if (isWxMini) {
          var link =
            "aid=" +
            _this.aid +
            "&ssid=" +
            _this.uid +
            "&uid=" +
            _this.userInfo.userId +
            "&gid=" +
            _this.list.gid;
          // wx.miniProgram.postMessage(link);
          wx.miniProgram.postMessage({
            data: [{ link: link }, { name: res.data.shareTitle }],
          });
          // res.data.shareTitle
        }
      });
      // userModel
      //   .addCounts({ aid: this.aid, uid: this.userInfo.userId, sid: this.uid })
      //   .then(res => {});
      // userModel
      //   .activityUserList({ aid: this.aid, page: 0, size: 33 })
      //   .then(res => {
      //     console.log(res);
      //     if (res.code == 200) {
      //       console.log(res.code);
      //       this.listimg = res.data;
      //       this.canyu2 = res.pageInfo.total;
      //     }
      //   });
      // userModel
      //   .shareUserList({ aid: this.aid, page: 0, size: 50 })
      //   .then(res => {
      //     console.log(res);
      //     if (res.code == 200) {
      //       console.log(res.code);
      //       this.listfenxiamg = res.data;
      //     }
      //   });

      //获取参与活动用户信息
      this.canyulist();
    },
    canyulist() {
      userModel
        .joinUserList({ aid: this.aid, page: this.page, size: this.size })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            let rows = res.data;
            this.loading = false;
            this.total = res.pageInfo.total;
            if (rows.length > 0) {
              rows.forEach((item, index) => {
                if (item.carNo) {
                  item.carNo =
                    item.carNo.substr(0, 3) + "**" + item.carNo.substr(5);
                }
                if (item.payDate) {
                  item.payDate = item.payDate.substr(-14);
                  item.payDate = item.payDate.substring(0, 11);
                }
              });
            }
            this.joinList = this.joinList.concat(rows);
            if (this.joinList.length >= this.total) {
              this.finished = true;
            }
          }
        });
    },
    chakangenduo() {
      this.page++;
      this.canyulist();
    },
    getConfig() {
      let that = this;
      let userAgent = navigator.userAgent;
      let shareUrl = "";
      if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
        shareUrl = sessionStorage.getItem("originUrl");
      } else {
        shareUrl = location.href;
      }
      userModel
        .config({
          url: shareUrl,
          gid: 208,
        })
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: "wx406b62706ded5aa8", // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: [
                "openLocation",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ], // 必填，需要使用的JS接口列表
            });

            // if (sessionStorage.getItem("fxisok") == "true") {
            //   var link =
            //     "https://marketing.car-posthouse.cn/user?aid=" +
            //     that.aid +
            //     "&ssid=" +
            //     0 +
            //     "&uid=" +
            //     0 +
            //     "&gid=" +that.list.gid;
            // } else {
            var ssid = that.uid ? that.uid : 0;
            var link =
              "https://marketing.car-posthouse.cn/user?aid=" +
              that.aid +
              "&ssid=" +
              ssid +
              "&uid=" +
              that.userInfo.userId +
              "&gid=" +
              that.list.gid;
            // }
            var desc =
              that.userInfo.realName + "已参与活动，邀你一起抢购共享优惠";
            var url = window.location.href;
            wx.ready(() => {
              //分享朋友圈
              wx.updateTimelineShareData({
                title: that.list.shareTitle,
                link: link,
                imgUrl: that.list.shareImg,
                success: function () {
                  console.log("执行");
                  that.zsisok = false;
                  setTimeout(function () {
                    // alert("分享成功！");
                    // that.cheshi = "分享成功"
                    userModel
                      .fenxianred({
                        aid: that.aid,
                        uid: that.userInfo.userId,
                      })
                      .then((res) => {
                        console.log(res);
                        if (res.code == 200) {
                        }
                      });
                  }, 500);
                },
              });
            });
            //分享给朋友
            wx.ready(() => {
              wx.updateAppMessageShareData({
                title: that.list.shareTitle,
                desc: desc,
                link: link,
                imgUrl: that.list.shareImg,
                success: function () {
                  that.zsisok = false;
                  // that.$toast.success("分享成功！");
                },
              });
            });
            wx.error(function (res) {
              // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，
              // 也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
              that.$toast.fail("分享权限获取失败 ！");
            });
            sessionStorage.setItem("config", JSON.stringify(res.data));
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    //输入车牌
    getple() {
      this.pleshow = true;
      this.$refs.ple.clickShowKeyboard();
    },
    getPlate(val) {
      this.addForm.carNo = val;
      this.pleshow = false;
    },
    getPlateb() {
      this.showKeyBoard = false;
    },
    onConfirm(value) {
      // this.value = value;
      var isdex = 0;
      console.log(value);
      this.shangjia = value;
      this.shangjia.activityItems.forEach((item, index) => {
        item.items.forEach((items, idx) => {
          items.index = isdex++;
        });
      });
      sessionStorage.setItem("LBshangjia", JSON.stringify(value));
      this.mendianshow = false;
    },
    getPlate2(val) {
      this.pleshow = false;
    },
    //复制链接成功
    copy() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      var http = this.pinzhenma.orderNumber;
      input.setAttribute("value", http);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$toast.success("复制成功！");
      }
      document.body.removeChild(input);
    },
    quxiaotu() {
      window.clearInterval(this.times);
      this.gzisok = false;
    },
    tocheshi() {},
    //提交审核
    tijiaoshenhe() {
      // WeixinJSBridge.call("closeWindow");
      this.gzisok = false;
    },
    goBack() {
      this.gzisok = false;
      // WeixinJSBridge.call("closeWindow");
      //replace替换原路由，作用是避免回退死循环
    },
    //点击下一步
    fenclick() {
      this.isfenxiang = false;
      this.ischaozuo = true;
    },
    //操作下一步
    chaozuoxyb() {
      if (this.xyb < 3) {
        this.xyb = this.xyb + 1;
      } else {
        this.ischaozuo = false;
        this.iszhezhao = false;
      }
      console.log(this.xyb);
    },
    // 车型。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。
    back() {
      this.chexinshow = false;
      this.cheisok = false;
    },
    tochexin() {
      this.cheisok = true;
      this.onLoad();
    },
    //保存车型
    baocun(item) {
      // console.log(item);
      var _this = this;
      this.carBrandLogo = this.imgs;
      this.chexin = item.brandName + "-" + item.seriesName;
      this.chexinshow = false;
      this.cheisok = false;
    },
    //搜索
    onSearch() {
      this.onLoad();
    },
    //搜索
    onSearch2() {
      const data = {
        brandId: this.brandId,
        seriesName: this.seriesName,
        // status: 2
      };
      userModel
        .findList(data)
        .then((e) => {
          this.carlist = e.data;
        })
        .catch(() => loading.clear());
    },
    chelist(item) {
      console.log(item);
      this.seriesName = "";
      this.imgs = item.ppinpaiLogoUrl;
      this.imgname = item.ppinpai;
      this.brandId = item.ppinpaiId;
      const data = {
        brandId: item.ppinpaiId,
        seriesName: this.seriesName,
        // status: 2
      };
      userModel
        .findList(data)
        .then((e) => {
          this.carlist = e.data;
          this.chexinshow = true;
          console.log(e.data);
        })
        .catch(() => loading.clear());
    },
    onLoad() {
      const data = {
        brandName: this.brandName,
        // status: 2
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      userModel
        .chepinpailist(data)
        .then((e) => {
          var arr = e.data;
          var hash = [];
          for (var i = 0; i < arr.length; i++) {
            if (hash.indexOf(arr[i].pshouzimu) == -1) {
              hash.push(arr[i].pshouzimu);
            }
          }
          let firstName = {};
          hash.map((c, i) => {
            firstName[c] = [];
            arr.map((d, i) => {
              if (c == d.pshouzimu) {
                firstName[c].push(d);
              }
            });
          });
          console.log(firstName);
          this.listchen = firstName;
          loading.clear();
        })
        .catch(() => loading.clear());
    },
    ctijiao() {
      var _this = this;
      try {
        if ((_this.chexin == "" || !_this.chexin) && this.checked == false) {
          _this.$toast("请先填写车型或勾选找不到车型！");
          return;
        }
        if (_this.chexin == "" || !_this.chexin) {
        } else {
          var chexin = this.chexin.split("-");
        }
        const data = {
          carNo: this.addForm.carNo,
          carBrandLogo: this.carBrandLogo,
          carBrand: chexin[0],
          carModel: chexin[1],
          gid: this.list.gid,
          mallUserId: this.userInfo.userId,
          // mallUserId: 9105,
          // mallUserId: 1775,
          name: this.name,
          phone: this.addForm.phone,
          haveChange: 1,
          haveAdopt: 1,
          onDefault: true, //是否默认车辆
        };

        userModel
          .carInfochexin(data)
          .then((e) => {
            _this.$toast("提交成功");
            this.goumaishow = false;
            _this.tanchuguanzhu();
          })
          .catch();
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
      }
    },
    tanchuguanzhu() {
      userModel
        .subscribe({
          uid: this.userInfo.userId,
          // uid:9105,
          gid: 208,
          // aid: 18
        })
        .then((res) => {
          if (res.data == 0) {
            this.gzisok = true;
          }
        });
    },
  },

  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
  beforeDestroy() {
    clearInterval(this.times);
    this.times = null;
  },
};
</script>

<style lang="less" scoped>
body {
  font-size: 14px;
}
// 车型页面
.chege {
  // height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9996;
  height: 100%;
  overflow-y: auto;
}
.xinshou {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.huadong {
  max-height: 100%;
  overflow-y: auto;
}
.van-popup {
  overflow: hidden;
}
.remen {
  font-size: 13px;
  font-weight: bold;
  background: #f7f8fa;
  padding: 0 12px;
  line-height: 35px;
}
.shousuo {
  position: fixed;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
}
.pinpai_img {
  width: 35px;
  height: 35px;
  vertical-align: middle;
}
.pinpai_txt {
  vertical-align: middle;
  display: inline-block;
  width: 85%;
  margin-left: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.van-index-anchor--sticky {
  top: 101px;
}
.chexi {
  font-size: 14px;
  line-height: 40px;
  border-bottom: 1px solid #f5f5f5;
  margin-left: 12px;
  color: #666;
}
.chexi:nth-child(1) {
  // margin-top: 45px;
}
.m_lis {
  margin-top: 45px;

  height: 45px;
  line-height: 41px;
  padding: 0 12px;
  border-bottom: 4px solid #f5f5f5;
  z-index: 99;
  background: #fff;
}
.imname {
  font-size: 16px;
  margin-left: 10px;
}

.indexWord {
  background: #f7f8fa;
  width: 100%;
  padding: 0 12px;
  line-height: 25px;
  color: #000;
}
.p_list {
  padding: 0 12px;
  height: 45px;
  line-height: 45px;
  color: #000;
}
.biao_ti {
  height: 46px;
  background: #fff;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
  color: #000;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
}

.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.remen_a {
  padding: 10px 10px;
  background: #fff;
  overflow: hidden;
}
.r_img {
  width: 35px;
  height: 35px;
  display: block;
  margin: auto;
}
.remen_b {
  display: inline-block;

  font-size: 13px;
  text-align: center;
  width: 20%;
}
.remen_b:nth-of-type(n + 6) {
  margin-top: 10px;
}
// 购买后弹窗
.gomform {
  overflow: hidden;
  width: 85%;
  font-size: 14px;
  border: 1px solid #333;
  padding: 10px 0 20px 0;
  border-radius: 8px;
}
.zhucexinxi {
  padding: 8px 15px;
  font-size: 14px;
}
.h1tite {
  text-align: center;
  font-size: 16px;
  padding-bottom: 10px;
}
.gmtishi {
  text-indent: 2em;
  padding: 8px 15px;
  font-weight: 500;
  font-size: 15px;
  color: #333;
  border-bottom: 10px solid #f5f5f5;
}
.zcheng {
  line-height: 40px;
}
.isgoms {
  line-height: 40px;
  height: 40px;
  overflow: hidden;
}
.gouxuan {
  margin-top: 10px;
  margin-left: 90px;
}
.anssniu {
  display: block;
  margin: auto;
  margin-top: 15px;
  height: 40px;
  width: 140px;
}
.tite {
  // font-family: 'FZZCHJW-GB';
  font-size: 40px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 50px;
  text-align: center;
  margin-top: 35px;
  font-weight: bold;
}
.ziti {
  font-size: 36px;
}
.titeisok {
  font-size: 36px;
}
.tite2 {
  font-family: "MFBanHei_Noncommercial-Regular";
  font-size: 30px;
  color: #ffeb4a;
  letter-spacing: 20px;
  text-align: center;
  margin-top: 25px;
  text-indent: 20px;
  line-height: 37px;
}
.tite3 {
  height: 31px;
  // width: 280px;

  text-align: center;

  margin: auto;
  color: #000;
  // font-family: 'FZZCHJW-GB';
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 31px;
  margin-top: 20px;
}
.tite3_a {
  padding: 0 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  min-width: 280px;
  // font-family: 'FZZCHJW-GB';
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  white-space: pre;
}
.tite4 {
  height: 21px;
  width: 340px;
  background: rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 10px;
  line-height: 21px;
  text-align: center;
  margin: auto;
  margin-top: 105px;
}
.XMlist {
  background: #ffffff;
  border-radius: 8px;
  width: 96%;
  margin: auto;
  // height: 300px;
  padding-bottom: 15px;
  margin-top: 5px;
  overflow: hidden;
}
.vxbeijin {
  position: relative;
  margin: auto;
  margin-top: 12px;
  background: url(../../assets/img/vxbeijin.png) no-repeat;
  background-size: 100% 100%;
  width: 94%;
  height: 102px;
  overflow: hidden;
}
.sp_beijin {
  background: url(../../assets/img/spbeijin.png) no-repeat;
  background-size: 100% 100%;
  width: 98%;
  margin: auto;
  height: 120px;
  margin-top: 7px;
  overflow: hidden;
  position: relative;
}
.song {
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
  height: 54px;
  background: #f9cc33;
  border-radius: 27px;
  margin-top: 10px;
  margin-right: 15px;
}
.song_a {
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #df3d48;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  margin-top: 6px;
}
.song_b {
  text-align: center;
  font-family: PingFangSC-Medium;
  color: #df3d48;
  letter-spacing: 0;
  line-height: 20px;
}
.jz_b {
  font-size: 11.71px;
}
.jz_c {
  font-family: PingFangSC-Medium;
  font-size: 18px;
}
.jz_d {
  font-family: PingFangSC-Medium;
  font-size: 11.71px;
}
.sp_img {
  float: left;
  width: 80px;
  height: 80px;
  margin-left: 55px;
  margin-top: 25px;
}
.sp_lis {
  overflow: hidden;
  margin-top: 30px;
}
.sp_lis_a {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 10px;
}
.sp_lis_b {
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #df3d48;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 11px;
  margin-left: 10px;
}
.sp_lis_c {
  font-family: PingFangSC-Medium;
  font-size: 10px;
  color: #666666;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: 2px;
  transform: scale(0.95);
  margin-left: 7px;
}
.huikui {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  margin-top: 22px;
  margin-left: 16px;
  width: 240px;
  text-align: left;
}
.jinyuan {
  font-family: "JDZhengHT-Bold";
  font-weight: bold;
  font-size: 38px;
  color: #ff2231;
  letter-spacing: 0;
}
.yunajia {
  width: 62%;
  background: #f58f20;
  border-radius: 14px;
  height: 28px;
  // width: 197px;
  margin-left: 15px;
  margin-top: 6px;
  text-align: center;
  // line-height: 28px;
  display: flex;

  align-items: center;
  justify-content: center;
}
.yunajia_A {
  margin-top: 2px;
  opacity: 0.79;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
}
.yunajia_b {
  font-family: PingFangSC-Medium;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 20px;
}
.xiangshou {
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #666666;
  letter-spacing: 0;
  text-align: center;
  margin-top: 35px;
}
.xiangshou_a {
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #df3d48;
  letter-spacing: 0;
  line-height: 17px;
}
// ------项目列表css样式
.list_xianmu {
  overflow: hidden;
}
.list_xianmu_a {
  background: url(../../assets/img/xiangmu.png) no-repeat;
  background-size: 100% 100%;
  width: 94%;
  height: 76px;
  margin: auto;
  margin-top: 6px;
  display: flex;
  align-items: center;
}
.xm_img {
  float: left;
  width: 84px;
  height: 58px;
  // margin-top: 8px;
  margin-left: 10px;
}
.xm_neirong {
  overflow: hidden;
  // margin-top: 8px;
  margin-left: 10px;
  width: 50%;
}
.xiche_a {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 20px;
  height: 20px;
  font-weight: bold;
}
.xiche_b {
  font-family: PingFangSC-Regular;
  font-size: 10px;
  color: #666666;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: 2px;
  height: 14px;
}
.xianmu_sm {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  font-weight: bold;
  color: #2a2a2a;
  letter-spacing: 0;
  line-height: 14px;
  padding-left: 2px;
  // margin-top: 13px;
}
.xianmu_sm2 {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  font-weight: bold;
  color: #2a2a2a;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: 4px;
  padding-left: 2px;
}
.jiazhi {
  overflow: hidden;
  margin-right: 12px;
  // margin-top: 12px;
  font-weight: bold;
  text-align: right;
  width: 70px;
}
.jiazhi_a {
  font-family: PingFangSC-Medium;
  font-size: 13px;
  color: #df3d48;
  letter-spacing: 0;
  line-height: 18px;
}
.jiazhi_b {
  font-family: JDZhengHT-Regular;
  font-size: 18px;
  color: #df3d48;
  letter-spacing: 0;
  text-align: right;
  line-height: 22px;
  margin-top: 5px;
}
.xiche_c {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #df3d48;
  letter-spacing: 0;
  line-height: 20px;
  height: 20px;
  margin-top: 2px;
}
// ---------------
.prize_container {
  position: relative;
  overflow: hidden;
  height: 100px;
  min-height: 30px;
  max-height: 100px;
  width: 100%;
  color: #333;
  font-size: 14px;
  border-bottom: 4px solid #f5f5f5;
}

.yichu {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  line-height: 20px;
  color: #333333;
}
.dizhi {
  color: #333333;
}
.dizhineirong {
  float: right;
  width: 85%;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  line-height: 20px;
}
.ditu_biao {
  width: 24px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 12px;
  margin-top: -12px;
  // margin: auto;
}
.xuanze a img {
  width: 24px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 12px;
  margin-top: -12px;
}
.jiachu {
  line-height: 20px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}

.prize_ul {
  width: 100%;
  position: absolute;

  padding: 0px 8px;
}
.prize_item {
  line-height: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.h1_beijin {
  overflow: hidden;
  width: 100%;
  height: 633px;
  position: relative;
}
.msgHtmlBox /deep/ img {
  width: 100%;
}

.beijintu {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
}
.beijintu3 {
  width: 100%;
  z-index: -1;
  height: 558px;
}

.beijintu2 {
  width: 100%;
  z-index: -1;
  height: 100%;
}
.erweima {
  height: 85px;
  width: 85px;
}
.list {
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  padding: 2px 10px;
  overflow: hidden;
}
.list div {
  line-height: 20px;
}
.name_qx {
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  color: #fff;
  font-size: 14px;
}
.ql-editor {
  padding: 0px 8px;
  color: #fff;
}
.ql-editor strong {
  color: #fff !important;
}
.guanzhu {
  font-size: 13px;
  margin: 3px 8px;
  border-bottom: 1px solid #dcdcdc;
}
.can_touxiang {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
}
.can_name {
  vertical-align: middle;
  display: inline-block;
  width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.zhifu_yin {
  width: 55px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bott {
  width: 100%;
  position: fixed;
  color: #fff;
  // text-align: center;
  overflow: hidden;
  z-index: 99;
  bottom: 0;
  line-height: 52px;
}
.boda {
  width: 50%;
  background: url(../../assets/img/daoji.png) no-repeat;
  background-size: 100% 100%;
  // background: #f9cc33;
  height: 52px;
}
.fener {
  color: #fff200;
  font-weight: bold;
  width: 46px;
  text-align: center;
  font-size: 18px;
  margin-left: 4px;
  margin-top: 10px;
}

.activefener {
  color: #fff200;
  font-weight: bold;
  width: 46px;
  text-align: center;
  font-size: 22px;
  margin-left: 2px;
  margin-top: 8px;
}
.van-count-down {
  margin-top: 25px;
  color: #fff;
  line-height: 22px;
  // font-weight: bold;
  font-size: 16px;
  margin-right: 5px;
}
.jiandaot {
  float: left;
  overflow: hidden;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  color: #f82785;
  font-weight: bold;
  margin-right: 2px;
}
.jiandaot_t {
  font-size: 18px;
}
.jiandao {
  float: left;
  overflow: hidden;
  // display: inline-block;
  background: #f82785;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  // padding: 0px 3px;
  width: 16px;
  text-align: center;
}
.colon {
  float: left;
  // display: inline-block;
  line-height: 20px;
  margin: 0 1px;
  color: #f82785;
}
// .bott span {
//   color: #fff;
//   font-family: PingFangSC-Medium;
//   font-size: 18px;
//   letter-spacing: 0;
//   text-align: center;
// }
.ddhh {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  // background: #fff;
  vertical-align: middle;
}
.dis {
  width: 156px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 50%;
  margin-left: -78px;
  color: #013bba;
  transform: scale(0.7);
  font-size: 12px;
  line-height: 12px;
}
.lb_tijiao {
  width: 50%;
  float: left;
  background: #00bb12;
  height: 52px;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.lismendian:nth-child(1) {
  border-top: 1px solid #f5f5f5;
}
.lismendian {
  text-align: center;
  font-size: 16px;
  color: #333;
  line-height: 35px;
  border-bottom: 1px solid #f5f5f5;
}
.zhuyi {
  line-height: 21px;
  background: red;
  text-align: center;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
}
.na_pos2 {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 30px;
}
.na_pos {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 20px;
  margin-bottom: 9px;
}
.topp {
  // background-image: url(../../assets/img/toubei.png);
  // background-size: 100% 100%;
  // height: 558px;
  // background-position: center center;
  // // overflow: auto;
  // z-index: 91;
  position: absolute;
  width: 100%;
  overflow: auto;
  background: #013bba;
  height: 100%;
}
.yanshehong {
  background: #6c1000;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(../../assets/img/touhong2.png);
  background-size: 100% 100%;
  height: 330px;
  background-position: center center;
  z-index: 91;
}
.yanshehong2 {
  background: #058261;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(../../assets/img/mmm.jpg);
  background-size: 100% 100%;
  height: 330px;
  background-position: center center;
  z-index: 91;
}
.newbeijin {
  width: 94%;
  height: 102px;
  margin: auto;
  margin-top: 12px;
}
.newimg {
  width: 100%;
}

.dandian {
  height: 324px;
}
.yanshe {
  background: #013bba;
  /* height: 100%; */
  /* z-index: -9; */
  // margin-top: 558px;
  /* padding-top: 500px; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(../../assets/img/toubei.png);
  background-size: 100% 100%;
  height: 495px;
  background-position: center center;
  z-index: 91;
}
.tishic {
  text-indent: 2em;
  padding: 8px 15px;
  font-weight: 500;
  font-size: 16px;
  color: #333;
}
.hed_a {
  position: absolute;
  // background: #013bba;
  z-index: 9;
  left: 0;
  width: 100%;
  // margin-left: -47%;
  // height: 0px;
  // margin-top: 350px;
  top: 0;
  z-index: 99;
}
.touxiang {
  padding: 2px 8px;
  border-bottom: 4px solid #f5f5f5;
  max-height: 115px;
  overflow: hidden;
  min-height: 45px;
}
.tou_a {
  width: 30px;
  height: 30px;
  margin: 0 3px;
  border-radius: 50%;
}
.lun_a {
  height: 30px;
}
.jian {
  height: 16px;
  line-height: 16px;
  margin: 5px 0;
  color: #000;
  font-size: 13px;
}
.huang {
  margin-right: 5px;
  font-size: 13px;
  padding: 0 4px;
  font-weight: bold;
  float: left;
  border-radius: 9px;
  background: #ffd700;
  color: red;
}
.huodongshijian {
  position: absolute;
  top: 270px;
  left: 50%;
  margin-left: -190px;
  width: 380px;
  height: 20px;
  overflow: hidden;
}
.huodongshijian2 {
  position: absolute;
  top: 295px;
  left: 50%;
  margin-left: -190px;
  width: 380px;
  height: 20px;
  overflow: hidden;
}
.heng {
  display: inline-block;
}
.hdshijian {
  background: #fff;
  height: 20px;
  float: left;
  margin-left: 8px;
  font-size: 13px;
  width: 75px;
  line-height: 20px;
  text-align: center;
  color: red;
  font-weight: bold;
}
.hdshijian_b {
  border: 1px solid #fff;
  line-height: 18px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  height: 20px;
  width: 180px;
  float: left;
  text-align: center;
}
.heng_b {
  font-size: 12px;
  overflow: hidden;
  height: 20px;
}
.heng_a {
  background: #fff;
  line-height: 20px;
  height: 2px;
  width: 50px;
  margin-bottom: 10px;
}
.heng_a_c {
  line-height: 20px;
  height: 2px;
  width: 50px;
  margin-bottom: 10px;
}
.van-cell {
  padding: 0;
  // background-color: #f1f1f1;
}
.chege .van-cell {
  padding: 5px 0;
}
.neirong {
  height: 200px;
  position: absolute;
  bottom: 105px;
  left: 50%;
  margin-left: -43%;
  width: 86%;
  overflow: hidden;
  padding: 2px 0;
  // background: #6a5acd;

  border: 2px solid #ffd700;
  border-radius: 5px;
}
.huodongxq {
  font-size: 16px;
  color: #fff;
  // font-weight: bold
}
.shao {
  font-size: 16px;
  color: #ffd700;
  text-align: center;
}
.cctishi {
  text-align: center;
  font-size: 13px;
}
.xuhao {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.lis_naem {
  margin-left: 4px;
}
.vip {
  position: relative;
}
.car_no {
  text-align: right;
  position: absolute;
  top: 12%;
  right: 5%;
  color: #fff;
}
.money {
  text-align: center;
  position: absolute;
  top: 45%;
  right: 0;
  color: #fff;
  font-size: 1.2rem;
}
.vip_l {
  text-align: left;
  position: absolute;
  bottom: 10%;
  left: 10%;
  color: #865e24;
}
.vip_r {
  text-align: right;
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #865e24;
}
.poster {
  width: 100%;
  display: block;
}
.beijin {
  background-color: #fff;
  margin: 8px 10px;
  border-radius: 8px;
  padding-bottom: 2px;
  overflow: hidden;
}
.mian {
  // padding: 16px 10px 12px 10px;
  padding: 7px 0 5px 0;
  margin: 16px 10px 12px 10px;
  border: 1px dashed #dcdcdc;
  overflow: hidden;
}
.miansmnew {
  margin: 13px 12px 0 12px;
  padding: 0 0 7px 0;
  border: 1px dashed #dcdcdc;
  overflow: hidden;
}
.miansm {
  margin: 13px 12px 0 12px;
  padding: 7px 0;
  border: 1px dashed #dcdcdc;
  overflow: hidden;
}
.mian_a {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  line-height: 16px;
  font-weight: bold;
}

.mian_b {
  font-family: PingFangSC-Medium;
  font-size: 13px;
  color: #666666;
  letter-spacing: 0;
  text-align: center;
  margin-top: 4px;
  font-weight: bold;
}
.biaotid {
  width: 50%;
  // display: inline-block;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}
.chepeii {
  line-height: 20px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}
.chepeiib {
  line-height: 34px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}
.user_links {
  padding: 12px 0;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
}
.user_links2 {
  padding: 9px 0;
  font-size: 14px;
  margin-left: 10px;
  position: relative;
}

.dizhia {
  float: left;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jiehsu_d {
  font-size: 16px;
  float: left;
  display: inline-block;
  max-width: 91%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jiehsu_dd {
  float: left;
  display: inline-block;
}
.toude {
  width: 20px;
  height: 20px;
  border-radius: 25%;
  margin-top: 2px;
}
.ju_beijin {
  color: #fff;
  padding-left: 8px;
  padding-top: 15px;
  height: 120px;
  background: #ffa500;
  border-radius: 10px 10px 0 0;
  margin-top: 5px;
  overflow: hidden;
}
.renzhen {
  color: #000;
  // padding-left: 8px;
  // padding-top: 15px;
  // height: 100px;
  // background: #f5f5f5;
  overflow: hidden;
  // margin-top: 5px;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.renzhen_a {
  margin: 5px 10px 0px 10px;
  border-radius: 10px;
  // margin-top: 5px;
  background: #fff;
  height: 90px;
  overflow: hidden;
}
.hh2 {
  padding: 10px 12px;
  font-weight: bold;
  font-size: 14px;
  margin-top: 2px;
  line-height: 30px;
}
.hh3 {
  height: 80%;
  margin: 0 12px;
  background: #f9f9f9;
}
.shuju {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 40px;
  border-radius: 20px;
  margin-left: 4px;
  margin-bottom: 125px;
}
.fangwen {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 40px;
  border-radius: 20px;
  margin-left: 4px;
  margin-bottom: 265px;
  border-radius: 50%;
  background: #fff;
  padding: 1px;
}
.shujuhb {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 40px;
  border-radius: 20px;
  margin-left: 4px;
  margin-bottom: 170px;
}
.fxtops {
  margin-bottom: 215px;
}
.zszhezhao {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.zszhezhao img {
  width: 100%;
}
.shujuerw {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 40px;
  border-radius: 20px;
  margin-left: 4px;
  margin-bottom: 80px;
}
.renzhen_b {
  background: #fff;
  height: 320px;
  margin: 0px 10px;
  border-radius: 10px;
  border-top: 1px dashed #f5f5f5;
  overflow: hidden;
  padding-bottom: 10px;
}
.qy_code_wrapper {
  margin-left: 12px;
  width: 150px;
  margin: auto;
}
.qy_code_wrapper img {
  display: block;
  // text-align: center;
}
.tishi {
  font-size: 12px;
  // margin-left: 12px;
  color: #666;
  font-weight: bold;
  text-align: center;
}
.cc_ma {
  padding: 8px 0;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
.pzma {
  width: 26px;
  line-height: 16px;
  padding: 10px 6px;
  text-align: center;
  background: #333;
  color: #fff;
  height: 100%;
  font-size: 13px;
  border-radius: 5px 0 0 5px;
}
.renzhen_h1 {
  text-align: center;
  font-weight: bold;
  margin-top: 15px;
  margin-right: 3px;
  // font-size: 16px;
}
.renzhen_h2 {
  text-align: center;
  margin-top: 12px;
  font-size: 13px;
  color: #666;
}
.ju_h1 {
  margin-left: 5px;
  font-size: 18px;
  color: #ffd700;
}
.daoji {
  font-size: 16px;
  color: #fff;
  line-height: 20px;
  margin-right: 10px;
}
.ju_h2 {
  font-size: 15px;
  text-align: left;
  line-height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ju_h3 {
  margin-top: 2px;
  margin-left: 5px;
  font-size: 17px;
  overflow: hidden;
}
.foot {
  background: #ffffff;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 12px;
  font-size: 14px;
  color: #3c3535;
  letter-spacing: 0;
  font-family: PingFangSC-Medium;
  padding-bottom: 12px;
}
.huo {
  width: 15px;
  vertical-align: middle;
}
.jifen {
  font-size: 14px;
  font-weight: bold;
  color: #3c3535;
  line-height: 30px;
  text-align: center;
  background: #f9cc33;
  border-radius: 6px 6px 0 0;
  margin: 12px 12px 0;
}

.jifen_a {
  font-size: 13px;
  text-align: center;
  margin: 0 12px;

  border: 1px solid #f5f5f5;
  // border-top: none;
}
.jifen_c .van-col {
  color: #999;
  border-right: 1px solid #f5f5f5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jifen_d .van-col {
  background: #dcdcdc;
  border-radius: 4px;
  line-height: 28px;
}
.jifen_d {
  height: 28px;
  color: #666;
}
.jifen_c {
  height: 24px;
  border-bottom: 1px solid #f5f5f5;
  overflow: hidden;
}
.jifen_a .jifen_c:last-child {
  border-bottom: none;
}
.jifen_b .van-col {
  border-right: 1px solid #f5f5f5;
  text-align: center;
}
.jifen_b {
  background: #fff7dd;
  // border-bottom: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  height: 24px;
  overflow: hidden;
}
.gzhimg {
  margin-top: 20px;
  background: #013bba;
  padding-bottom: 20px;
  text-align: center;
}
.gzhimgc {
  padding-bottom: 20px;
}
.erweima {
  display: block;
  margin: auto;
  width: 200px;
  height: 200px;
}
.zi {
  text-shadow: #ff0000 5px 5px 5px;
  color: white;
  font-size: 60px;
}
// --------------
.car-number {
  height: 50px;
}
.item {
  border: 1px solid #013bba;
  border-radius: 2px;
  width: 24px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  float: left;
  margin-right: 5px;
}
.item:last-child {
  background: #4b84ff;
  border: 1px solid #013bba;
}
#key-board {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.gzname {
  font-size: 12px;
  background: #fff;
  display: inline-block;
  padding: 1px 12px;
  color: #013bb9;
  border-radius: 6px;
  margin-bottom: 12px;
}
.gzname_a {
  font-size: 12px;
  color: #fff;
  // margin-top: 10px;
}
// ---------------遮罩层--------------//
.zhezhao {
  position: fixed;
  top: 0;
  height: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.fen_img {
  background: url(../../assets/img/fenxian.png) no-repeat;
  background-size: 100% 100%;
  height: 394px;
  width: 319px;
  text-align: center;
  margin: auto;
  margin-top: 33.6%;
}
.fen_tit {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #e93f33;
  letter-spacing: 0;
  text-align: center;
  padding-top: 5%;
}
.fen_jin {
  padding-top: 10%;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #f5503a;
  letter-spacing: 0;
  text-align: center;
}
.fen_jin span {
  font-size: 27px;
}
.fen_shuoming {
  font-size: 13px;
  line-height: 17px;
}
.fen_tishi {
  padding-top: 19%;
  color: #fff;
}
.fen_xia {
  background: #fee99e;
  border-radius: 19px;
  width: 90px;
  height: 38px;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  margin: auto;
  margin-top: 3px;
  color: #000000;
}
.ico_a {
  width: 49px;
  margin-left: 9px;
  margin-top: 4px;
  vertical-align: middle;
}
.ico_name {
  vertical-align: middle;
  color: #fff;
  margin-left: 5px;
}
.chaozuo {
  position: relative;
  background: #fff;
  overflow: hidden;
  width: 75%;
  height: 90%;
  margin: auto;
  margin-top: 10%;
  padding: 5px 5px 35px 5px;
  border-radius: 15px;
  text-align: center;
}
.shiyi_name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.beian {
  width: 15px;
  vertical-align: middle;
  margin-right: 3px;
}
.czimg {
  width: 100%;
  height: 100%;

  display: block;
}
.czxiayibu {
  color: red;
  position: fixed;
  bottom: 0;
  width: 100px;
  border: 3px solid red;
  text-align: center;
  margin-bottom: 10px;
  left: 50%;
  margin-left: -50px;
  z-index: 9;
  font-weight: bold;
}
.my-swipe {
  margin-top: 8px;
  overflow: hidden;
  margin-bottom: 8px;
  border-radius: 5px;
}
.my-swipe .van-swipe-item {
  overflow: hidden;
  color: #fff;
  text-align: center;
  position: relative;
}
.van-swipe-item img {
  border-radius: 5px;
  width: 95%;
  height: 127px;
  margin: auto;
  display: block;
}
.fen_shu {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  margin-bottom: 10px;
  width: 36px;
  margin-left: 42%;
  color: #2b70d0;
  font-weight: bold;
}
.tuijianimg {
  width: 95%;
  display: block;
  margin: auto;
  border-radius: 5px;
}
</style>
<style scoped>
/* ----------------- */
.msgHtmlBox >>> img {
  width: 100%;
  /* display: flex; */
}
.msgHtmlBox >>> p {
  color: #fff;
}
.msgHtmlBox {
  margin-top: 5px;
  white-space: pre-wrap;
}
/* .msgHtmlBox >>> strong {
  font-weight: bold;
} */
</style>
<style>
.van-picker__confirm {
  color: #67c23a;
}
.van-picker__cancel {
  font-size: 16px;
}
.van-picker__confirm {
  font-size: 16px;
}
</style>